import { ChangeDetectionStrategy, Component } from '@angular/core';

import { PspResultPageBaseComponent } from '@bp/checkout-frontend/components';
import { Transaction } from '@bp/checkout-frontend/models';

@Component({
	selector: 'bp-card-success-page',
	standalone: false,
	templateUrl: './card-success-page.component.html',
	styleUrls: [ './card-success-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardSuccessPageComponent extends PspResultPageBaseComponent<Transaction | null> {

	protected _redirectUrl: string | null = null;

	protected _i18Prefix = `page.${ this._session.payMode ? 'pay' : 'deposit' }-card-success${ this._transaction?.isApprovedOnHold
		? '-on-hold'
		: (this._transaction?.isAuthorized ? '-authorized' : '')
	}`;

	protected _i18nTitleKey = `${ this._i18Prefix }.title`;

	protected _i18nContentKey = this._transaction?.isApprovedOnHold || this._transaction?.isAuthorized
		? `${ this._i18Prefix }.text`
		: `page.${ this._session.payMode ? 'pay' : 'deposit' }-card-success.text`;

	constructor() {
		super();

		this._appService.clearPaymentRequestFromStorage();

		this._notifyHostAboutPaymentStatus('approved');

		if (this._session.url?.success)
			this._redirectUrl = this._buildRedirectUrl(this._session.url.success, 'approved');

		this._whenCanContinueScheduleContinueExecution();

		this._whenPaywithCanContinueScheduleContinueExecution();
	}
}
