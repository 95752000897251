import { TransactionStatus as SharedTransactionStatus } from '@bp/shared/models/business';

export class TransactionStatus extends SharedTransactionStatus {

	/**
	 * When the back starts sending bullshit
	 */
	static readonly unknown = new TransactionStatus();

	/**
	 * Computed based on the `is_cancelled` field
	 */
	static readonly cancelled = new TransactionStatus();

}
