import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';

import { AppService } from '@bp/checkout-frontend/providers';
import { IPaymentMethod, IBank, CheckoutSession } from '@bp/checkout-frontend/models';

@Component({
	selector: 'bp-banks-page',
	standalone: false,
	templateUrl: './banks-page.component.html',
	styleUrls: [
		'../general-payment-options/general-payment-options-page.component.scss',
		'./banks-page.component.scss',
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BanksPageComponent extends Destroyable {

	protected readonly _appService = inject(AppService);

	private readonly __router = inject(Router);

	private readonly __activatedRoute = inject(ActivatedRoute);

	protected get _session(): CheckoutSession {
		return this._appService.session!;
	}

	protected _paymentMethod!: IPaymentMethod;

	protected _banks: IBank[] = [];

	constructor() {
		super();

		this.__activatedRoute.params
			.pipe(takeUntilDestroyed(this))
			.subscribe(({ provider, bankPaymentType, subtype }) => {
				this._paymentMethod = this._session.paymentMethods
					.find(v => !!v.banks && v.provider === provider && v.subtype.name === subtype)!;

				this._banks = bankPaymentType
					? this._paymentMethod.banks!.filter(v => v.payment_types!.includes(bankPaymentType))
					: this._paymentMethod.banks!;
			});
	}

	protected _navigate(bank: IBank): void {
		this._appService.navigate(
			[
				bank.bank_code,
				bank.currency.code,
				{ backBtnUrl: this.__router.url },
			],
			{
				relativeTo: this.__activatedRoute,
			},
		);
	}
}
