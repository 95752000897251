/* eslint-disable @typescript-eslint/naming-convention */
import { fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
	Component, Input, ChangeDetectionStrategy, ElementRef, Output, EventEmitter, OnInit, inject
} from '@angular/core';

import { Dictionary } from '@bp/shared/typings';

import { Destroyable } from '@bp/frontend/models/common';

import { documentWrite } from '@bp/checkout-frontend/utilities';

@Component({
	selector: 'bp-psps-device-fingerprint-collector[pspsFingerprintCollectionHtmls]',
	standalone: false,
	templateUrl: './psps-device-fingerprint-collector.component.html',
	styleUrls: [ './psps-device-fingerprint-collector.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PspsDeviceFingerprintCollectorComponent extends Destroyable implements OnInit {

	private readonly __$host = <HTMLElement>inject(ElementRef).nativeElement;

	@Input() pspsFingerprintCollectionHtmls!: Dictionary<string>;

	@Output() readonly pspsFingerprints = new EventEmitter<Dictionary<string>>();

	@Output() readonly allFingerprintsCollected = new EventEmitter();

	private __pspsFingerprints: Dictionary<string> = {};

	ngOnInit(): void {
		Object
			.values(this.pspsFingerprintCollectionHtmls)
			.forEach(fingerprintHtml => void this.__executeFingerprint(fingerprintHtml));

		this.__listenForFingerprintResultAndEmitIt();
	}

	private __executeFingerprint(fingerprintHtml: string): void {
		const $iframe = document.createElement('iframe');

		this.__$host.append($iframe);

		documentWrite($iframe.contentDocument!, fingerprintHtml);
	}

	private __listenForFingerprintResultAndEmitIt(): void {
		fromEvent<MessageEvent<{ event?: string; fingerprint: string; psp_name: string } | undefined>>(window, 'message')
			.pipe(takeUntil(this.destroyed$))
			.subscribe(({ data }) => {
				if (!data?.event?.startsWith('[bp]:'))
					return;

				if (data.event === '[bp]:fingerprint') {
					this.__pspsFingerprints[data.psp_name] = data.fingerprint;

					this.pspsFingerprints.emit(this.__pspsFingerprints);
				}

				if (Object.keys(this.__pspsFingerprints).length === Object.keys(this.pspsFingerprintCollectionHtmls).length)
					this.allFingerprintsCollected.emit();
			});
	}
}
