import { Component, ChangeDetectionStrategy, inject } from '@angular/core';

import { PaymentOptionInstancesManager } from '@bp/checkout-frontend/providers';

@Component({
	selector: 'bp-payment-instance-out-of-total-text',
	standalone: false,
	templateUrl: './payment-instance-out-of-total-text.component.html',
	styleUrls: [ './payment-instance-out-of-total-text.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentInstanceOutOfTotalTextComponent {

	protected readonly _paymentOptionInstancesManager = inject(PaymentOptionInstancesManager);

}
