import { Component, Input, ChangeDetectionStrategy, HostBinding } from '@angular/core';

import { FADE } from '@bp/frontend/animations';

@Component({
	selector: 'bp-processing-stub',
	standalone: false,
	templateUrl: './processing-stub.component.html',
	styleUrls: [ './processing-stub.component.scss' ],
	animations: [ FADE ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessingStubComponent {

	@Input() show!: boolean | null;

	@Input()
	@HostBinding('class.inline')
	inline!: boolean | null;

	@Input() redirecting!: boolean | null;

	@Input() retrying!: boolean | null;

	@Input() checking!: boolean | null;

	@Input() secure!: boolean | null;

	@Input() newTab!: boolean | null;

}
