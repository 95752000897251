import { interval, combineLatest } from 'rxjs';
import { map, startWith, takeWhile, shareReplay, takeUntil, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { SLIDE } from '@bp/frontend/animations';
import { takeUntilDestroyed } from '@bp/frontend/models/common';

import { IFramePageComponent } from '../iframe-page';

const TIMEOUT_BEFORE_MANUAL_TRANSACTION_CHECK = 10 * 60; // seconds

@Component({
	selector: 'bp-3d-secure-page',
	standalone: false,
	templateUrl: './3d-secure-page.component.html',
	styleUrls: [ './3d-secure-page.component.scss' ],
	animations: [ SLIDE ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThreeDSecurePageComponent extends IFramePageComponent implements OnInit {

	protected _countDown$ = this._showProcessingStub$
		.pipe(
			distinctUntilChanged(),
			filter(v => !!v),
			// reset the count down on the iframe reloading
			switchMap(() => interval(1000).pipe(
				startWith(0),
				map(passed => TIMEOUT_BEFORE_MANUAL_TRANSACTION_CHECK - passed),
				takeWhile(it => it >= 0),
			)),
			shareReplay({ bufferSize: 1, refCount: true }),
			takeUntil(this.destroyed$),
		);

	protected _countdownIsUp$ = this._countDown$.pipe(
		startWith(false),
		map(it => it === 0),
		distinctUntilChanged(),
		shareReplay({ bufferSize: 1, refCount: true }),
		takeUntil(this.destroyed$),
	);

	protected _processing$ = combineLatest(
		this._showProcessingStub$,
		this._countdownIsUp$,
		(iframeLoading, countdownIsUp) => iframeLoading || countdownIsUp,
	);

	override ngOnInit(): void {
		super.ngOnInit();

		this._countdownIsUp$
			.pipe(
				filter(it => !!it),
				takeUntilDestroyed(this),
			)
			.subscribe(() => void this._depositProcessingService.checkPspTransactionStatus(
				this._appService.transaction!.id,
			));
	}
}
