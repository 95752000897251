import { NgModule } from '@angular/core';
import { NavigationError, RouterModule, Routes } from '@angular/router';

import { BpError } from '@bp/frontend/models/core';
import { TelemetryService } from '@bp/frontend/services/telemetry';

import { ErrorPageComponent, NotFoundPageComponent } from './core';

// app routing module is for lazy routes

const routes: Routes = [
	{ path: 'error', component: ErrorPageComponent },
	{ path: '**', component: NotFoundPageComponent },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			resolveNavigationPromiseOnError: true,
			enableTracing: false,
			paramsInheritanceStrategy: 'always',
			bindToComponentInputs: true,
			errorHandler(error: unknown): void {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				const unwrappedError = error instanceof NavigationError ? error.error : error;

				if (unwrappedError instanceof BpError && unwrappedError.isHttpError)
					return;

				TelemetryService.routerErrorHandler(unwrappedError);
			},
		}),
	],
	exports: [ RouterModule ],
})
export class AppRoutingModule { }
