import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PaymentOptionType } from '@bp/shared/models/business';

import { SessionResolver } from '@bp/checkout-frontend/providers';

import {
	GeneralPaymentOptionsPageComponent, CardPageComponent, CryptoPageComponent,
	WireTransferPageComponent, ThreeDSecurePageComponent, ApmPageComponent, CryptoQrPageComponent, BanksPageComponent, IFramePageComponent, CryptoWalletsPageComponent,
	BanksPaymentTypesPageComponent, PaywithPaymentOptionsPageComponent, ApmWireTransferDetailsPageComponent
} from './pages';

const routes: Routes = [
	{
		path: 'payments',
		runGuardsAndResolvers: 'always',
		resolve: {
			session: SessionResolver,
		},
		children: [
			{
				path: '',
				component: GeneralPaymentOptionsPageComponent,
			},
			{
				path: `${ PaymentOptionType.creditCard.name }/:provider/:currencyCode`,
				component: CardPageComponent,
			},
			{
				path: `${ PaymentOptionType.creditCard.name }/:paymentMethodType/:provider/:currencyCode`,
				component: CardPageComponent,
			},
			{
				path: `${ PaymentOptionType.apm.name }/:provider/:subtype/:currencyCode`,
				component: ApmPageComponent,
			},
			{
				path: `${ PaymentOptionType.crypto.name }/:provider/:currencyCode`,
				component: CryptoPageComponent,
			},
			{
				path: PaymentOptionType.cryptoWallet.name,
				component: CryptoWalletsPageComponent,
			},
			{
				path: PaymentOptionType.wireTransfer.name,
				component: WireTransferPageComponent,
			},
			{
				path: 'apm-wire-transfer-details',
				component: ApmWireTransferDetailsPageComponent,
			},
			{
				path: '3d-secure',
				component: ThreeDSecurePageComponent,
			},
			{
				path: 'crypto-qr',
				component: CryptoQrPageComponent,
			},
			{
				path: PaymentOptionType.paywith.name,
				children: [
					{
						path: '',
						component: PaywithPaymentOptionsPageComponent,
					},
				],
			},
			{
				path: `${ PaymentOptionType.banks.name }/:provider/:subtype`,
				children: [
					{
						path: '',
						component: BanksPageComponent,
					},
					{
						path: ':bankCode/:currencyCode',
						component: ApmPageComponent,
					},
				],
			},
			{
				path: 'banks-payment-types/:provider/:subtype',
				children: [
					{
						path: '',
						component: BanksPaymentTypesPageComponent,
					},
					{
						path: ':bankPaymentType',
						children: [
							{
								path: '',
								component: BanksPageComponent,
							},
							{
								path: ':bankCode/:currencyCode',
								component: ApmPageComponent,
							},
						],
					},
				],
			},
			{
				path: 'iframe',
				component: IFramePageComponent,
			},
		],
	},
];

@NgModule({
	imports: [ RouterModule.forChild(routes) ],
	exports: [ RouterModule ],
})
export class PaymentsRoutingModule { }
