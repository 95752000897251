import { Component, ChangeDetectionStrategy, inject } from '@angular/core';

import { AppService } from '@bp/checkout-frontend/providers';

@Component({
	selector: 'bp-not-found-page',
	standalone: false,
	templateUrl: './not-found-page.component.html',
	styleUrls: [ '../error/error-page.component.scss', './not-found-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundPageComponent {

	protected readonly _appService = inject(AppService);

	constructor() {
		this._appService.markAppReady();
	}
}
