import { Component, HostListener, Input, HostBinding, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'bp-dots-wave',
	standalone: false,
	templateUrl: './dots-wave.component.html',
	styleUrls: [ './dots-wave.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DotsWaveComponent {
	@Input()
	@HostBinding('class.show')
	show!: boolean | null;

	@HostListener('click', [ '$event' ])
	onClick(event: Event): void {
		if (this.show) {
			event.stopPropagation();

			event.preventDefault();
		}
	}
}
