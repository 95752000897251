import { BehaviorSubject, map, of, switchMap, timer } from 'rxjs';

import { Component, ChangeDetectionStrategy, OnInit, inject } from '@angular/core';

import { FADE } from '@bp/frontend/animations';
import { takeUntilDestroyed } from '@bp/frontend/models/common';
import { pending } from '@bp/frontend/rxjs';
import { BpError } from '@bp/frontend/models/core';
import { MockedBackendState } from '@bp/frontend/services/persistent-state-keepers';

import { PspResultPageBaseComponent } from '@bp/checkout-frontend/components';
import { TransactionInfo } from '@bp/checkout-frontend/models';
import { PaymentApiService } from '@bp/checkout-frontend/providers';

@Component({
	selector: 'bp-status-page',
	standalone: false,
	templateUrl: './status-page.component.html',
	styleUrls: [ './status-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
})
export class StatusPageComponent extends PspResultPageBaseComponent<TransactionInfo> implements OnInit {

	private readonly __paymentsApi = inject(PaymentApiService);

	protected _redirectUrl!: string;

	private readonly __checkingTransaction$ = new BehaviorSubject(false);

	protected _checkingTransaction$ = this.__checkingTransaction$.pipe(
		// 1500ms time to give the user to read the message
		switchMap(value => value ? of(true) : timer(1500).pipe(map(() => false))),
	);

	constructor() {
		super();
	}

	ngOnInit(): void {
		this.__handleTransactionStatus(this._transaction);
	}

	private __handleTransactionStatus(transaction: TransactionInfo): void {
		if (this._transaction.isPendingOrInProcess)
			setTimeout(() => void this.__checkPspTransactionStatus(transaction), (MockedBackendState.isActive ? 2 : 5) * 1000);
		else if (this._transaction.isTryAgain) {
			this._appService.navigate(
				[ 'v2', 'deposit-processing' ],
				{
					queryParams: {
						id: transaction.id,
						status: transaction.status,
						sessionId: this._session.checkoutSessionId,
					},
				},
			);
		} else if (this._appService.isPaywithMode)
			this._initPaywithContinueWorkflow();
		else {
			this.__notifyHost(this._transaction);

			this.__tryInitContinueWorkflow();
		}

		this._cdr.detectChanges();
	}

	private _initPaywithContinueWorkflow(): void {
		if (this._transaction.isApproved)
			this._paymentOptionInstancesManager.successContinue(this._transaction);
		else
			this._paymentOptionInstancesManager.handleDeclinedTransaction();
	}

	private __tryInitContinueWorkflow(): void {
		if (this._session.url) {
			if (this._session.url.failure && this._transaction.isDeclined)
				this._redirectUrl = this._buildRedirectUrl(this._session.url.failure, this._transaction.status!);

			if (this._session.url.success && this._transaction.isApproved)
				this._redirectUrl = this._buildRedirectUrl(this._session.url.success, this._transaction.status!);

			if (this._session.url.cancel && this._transaction.isCancelled)
				this._redirectUrl = this._buildRedirectUrl(this._session.url.cancel, this._transaction.status!);
		}

		if (this._transaction.isApproved || this._transaction.isDeclined || this._transaction.isCancelled)
			this._appService.clearPaymentRequestFromStorage();

		this._whenCanContinueScheduleContinueExecution();
	}

	private __checkPspTransactionStatus(transaction: TransactionInfo): void {
		this.__paymentsApi
			.checkPspTransactionStatus(transaction.id)
			.pipe(
				takeUntilDestroyed(this),
				pending(this.__checkingTransaction$),
			)
			.subscribe({
				next: freshTransaction => {
					this._appService.transaction = this._transaction = freshTransaction;

					this.__handleTransactionStatus(this._transaction);
				},
				error: (error: unknown) => {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					if (error instanceof BpError && (error.payload)?.status) {
						this._appService.transaction = this._transaction = new TransactionInfo(error.payload);

						this.__handleTransactionStatus(this._transaction);
					} else {
						this._appService.setError(BpError.fromUnknown(error));

						this._appService.navigate([ '/error' ]);
					}
				},
			});
	}

	getTexts(): { title: string; description?: string; description2?: string } {
		const payModePrefix = this._session.payMode ? 'pay' : 'deposit';

		switch (this._transaction.status) {
			case 'capture_in_progress':

			case 'authorized':

			case 'approved':
				return {
					title: `page.${ payModePrefix }-card-success${ this._transaction.isAuthorized ? '-authorized' : '' }.title`,
					description: `page.${ payModePrefix }-card-success${ this._transaction.isAuthorized ? '-authorized' : '' }.text`,
				};

			case 'approved_on_hold':
				return {
					title: `page.${ payModePrefix }-card-success-on-hold.title`,
					description: `page.${ payModePrefix }-card-success-on-hold.text`,
				};

			case 'voided':

			case 'cancelled':
				return {
					title: 'page.card-cancelled.title',
				};

			case 'error':
				return {
					title: 'page.error.default-title',
				};

			case 'pending':
				return {
					title: 'page.card-pending.title',
					description: `page.${ this._session.payMode ? 'pay' : 'deposit' }-card-pending.text`,
				};

			case 'declined_due_to3_d_auth_failure':

			case 'three_ds_not_authenticated':
				return {
					title: 'page.card-declined.title',
					description: 'Card authentication was unsuccessful',
				};

			default:
				if (this._transaction.isDeclined) {
					return {
						title: 'page.card-declined.title',
					};
				}

				throw new Error(`Unknown transaction status: ${ this._transaction.status }`);
		}

	}

	private __notifyHost(transaction: TransactionInfo): void {
		const shortStatus = transaction.isApproved
			? 'approved'
			: (transaction.isDeclined ? 'declined' : 'cancelled');

		this._notifyHostAboutPaymentStatus(shortStatus);
	}
}
