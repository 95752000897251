import { timer } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import moment from 'moment';

import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'bp-countdown',
	standalone: false,
	template: 'in {{ in$ | async }}',
	styleUrls: [ './countdown.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountdownComponent {

	@Input() upAt!: moment.Moment;

	@Output() readonly up = new EventEmitter<void>();

	in$ = timer(0, 1000)
		.pipe(
			map(() => this.upAt.diff(moment())),
			tap(left => left <= 0 && void this.up.emit()),
			map(left => moment(left).format('mm:ss')),
			takeUntil(this.up),
		);
}
