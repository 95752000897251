/* eslint-disable @typescript-eslint/naming-convention */
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Stringify } from '@bp/shared/typings';
import { PaymentOptionType } from '@bp/shared/models/business';

import { bypassAuthorizationHeaderCheck, retryOnAnyTechnicalError, CF_WORKER_PATH_SEGMENT, retryOnNoResponseErrorOnly } from '@bp/frontend/services/http';
import { TelemetryService } from '@bp/frontend/services/telemetry';

import { CheckoutSession, IRequestSession, PaymentOptionInstance } from '@bp/checkout-frontend/models';

@Injectable({
	providedIn: 'root',
})
export class SessionApiService {

	private readonly __http = inject(HttpClient);

	create(request: IRequestSession): Observable<CheckoutSession> {
		return this.__http
			.post<Partial<Stringify<CheckoutSession>>>(
				'v2/session/create',
				request,
				{
					context: bypassAuthorizationHeaderCheck(
						retryOnAnyTechnicalError(),
					),
				},
			)
			.pipe(map(v => new CheckoutSession(v)));
	}

	getOnFirstCashierInit(sessionId: string, request: IRequestSession): Observable<CheckoutSession> {
		return this.__http
			.post<Partial<Stringify<CheckoutSession>>>( // should be get but the back has it as post
				'v2/session/create',
				{
					...request,
					cashier_token: sessionId,
				},
				{
					context: bypassAuthorizationHeaderCheck(
						retryOnAnyTechnicalError(),
					),
				},
			)
			.pipe(map(v => new CheckoutSession(v)));
	}

	get(sessionId: string): Observable<CheckoutSession> {
		return this.__http
			.get<Partial<Stringify<CheckoutSession>>>(
				`v2/session/${ sessionId }`,
				{ context: bypassAuthorizationHeaderCheck() },
			)
			.pipe(map(v => new CheckoutSession(v)));
	}

	savePaymentMethodInstances(paymentMethodInstances: PaymentOptionInstance[], sessionId: string): Observable<void> {
		return this.__http
			.post<void>(
				`v2/session/payment-method-instances/${ sessionId }`,
				{
					payment_method_instances: paymentMethodInstances.map(
						instance => ({
							id: instance.id,
							amount: instance.amount,
							status: instance.status,
							payment_method_type: (instance.paymentMethodType.isBanks ? PaymentOptionType.apm : instance.paymentMethodType).snakeCase,
							payment_method_psp_name: instance.paymentMethodPspName,
							processing_date: instance.processingDate,
						}),
					),
				},
			);
	}

	getSuspensionStatus(checkoutId: string): Observable<boolean> {
		return this.__http
			.get<boolean>(
				`${ CF_WORKER_PATH_SEGMENT }/bridger-admin/merchants-block-list/checkouts/${ checkoutId }`,
				{ context: retryOnNoResponseErrorOnly() },
			)
			.pipe(catchError((error: unknown) => {
				TelemetryService.captureError(error);

				return [ false ];
			}));
	}
}
