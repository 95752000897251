import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'bp-secure-payment-static-text',
	standalone: false,
	templateUrl: './secure-payment-static-text.component.html',
	styleUrls: [ './secure-payment-static-text.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		class: 'mat-body-1',
	},
})
export class SecurePaymentStaticTextComponent {

}
