import { timer } from 'rxjs';

import { Component, ChangeDetectionStrategy, inject } from '@angular/core';

import { takeUntilDestroyed } from '@bp/frontend/models/common';
import { BpError } from '@bp/frontend/models/core';

import { PspResultPageBaseComponent } from '@bp/checkout-frontend/components';
import { PaymentApiService } from '@bp/checkout-frontend/providers';
import { IApiTransaction, Transaction, TransactionInfo } from '@bp/checkout-frontend/models';

@Component({
	selector: 'bp-card-pending-page',
	standalone: false,
	templateUrl: './card-pending-page.component.html',
	styleUrls: [ './card-pending-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardPendingPageComponent extends PspResultPageBaseComponent<Transaction | null> {

	private readonly __paymentsApi = inject(PaymentApiService);

	protected _redirectUrl: string | null = null;

	constructor() {
		super();

		this._notifyHostAboutPaymentStatus('pending');

		if (this._transaction) {
			this.__handleAccordingTransaction(this._transaction);

			if (this._session.url?.pending) {
				this._redirectUrl = this._buildRedirectUrl(
					this._session.url.pending,
					this._transaction.status!,
				);
			}
		}
	}

	private __checkTransaction(transaction: TransactionInfo): void {
		this.__paymentsApi
			.transaction(transaction.id)
			.pipe(takeUntilDestroyed(this))
			.subscribe(
				{
					next: freshTransaction => void this.__handleAccordingTransaction(freshTransaction),

					// eslint-disable-next-line rxjs/no-implicit-any-catch
					error: (error: BpError<IApiTransaction>) => void this.__handleAccordingTransaction(error.payload
						? new Transaction(error.payload)
						: null),
				},
			);
	}

	private __handleAccordingTransaction(transaction: Transaction | null): void {
		if (!transaction)
			this._appService.navigate([ '/error' ]);

		this._appService.transaction = transaction;

		if (transaction?.isPendingOrInProcess || transaction?.isTryAgain) {
			timer(5 * 1000)
				.pipe(takeUntilDestroyed(this))
				.subscribe(() => void this.__checkTransaction(transaction));
		} else
			this._appService.navigateAccordingFinalTransactionStatus(transaction);
	}

}
