import { inject, NgModule, provideAppInitializer } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentCardExpireInputComponent } from '@bp/frontend/components/payment-card/expire-input';
import { PaymentCardCvvInputComponent } from '@bp/frontend/components/payment-card/cvv-input';
import { PaymentCardNumberInputComponent } from '@bp/frontend/components/payment-card/number-input';

import { SharedModule } from '@bp/checkout-frontend/shared';

import { BloxRoutingModule } from './blox-routing.module';
import { BloxControllerPageComponent, BloxPageComponent } from './pages';
import { BloxService } from './services';

const EXPOSED = [
	BloxControllerPageComponent,
	BloxPageComponent,
];

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		BloxRoutingModule,

		PaymentCardCvvInputComponent,
		PaymentCardExpireInputComponent,
		PaymentCardNumberInputComponent,
	],
	declarations: EXPOSED,
	exports: EXPOSED,
	providers: [
		provideAppInitializer(() => {
			inject(BloxService);
		}),
	],
})
export class BloxModule { }
