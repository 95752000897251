import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Destroyable } from '@bp/frontend/models/common';

import { AppService } from '@bp/checkout-frontend/providers';
import { CheckoutSession } from '@bp/checkout-frontend/models';

@Component({
	selector: 'bp-banks-payment-types-page',
	standalone: false,
	templateUrl: './banks-payment-types-page.component.html',
	styleUrls: [
		'../general-payment-options/general-payment-options-page.component.scss',
		'./banks-payment-types-page.component.scss',
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BanksPaymentTypesPageComponent extends Destroyable {

	protected readonly _appService = inject(AppService);

	private readonly __router = inject(Router);

	private readonly __activatedRoute = inject(ActivatedRoute);

	protected get _session(): CheckoutSession {
		return this._appService.session!;
	}

	protected _paymentMethod = this._session.paymentMethods
		.find(v => v.type.isBanks && v.provider === this.__activatedRoute.snapshot.params['provider'])!;

	protected _navigate(bankPaymentType: string): void {
		this._appService.navigate(
			[
				bankPaymentType,
				{
					backBtnUrl: this.__router.url,
				},
			],
			{
				relativeTo: this.__activatedRoute,
			},
		);
	}
}
