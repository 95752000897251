
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Destroyable } from '@bp/frontend/models/common';

import { AppService } from '@bp/checkout-frontend/providers';

@Component({
	selector: 'bp-three-ds-processing-result-callback-page',
	standalone: false,
	templateUrl: './three-ds-processing-result-callback-page.component.html',
	styleUrls: [ './three-ds-processing-result-callback-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThreeDsProcessingResultCallbackPageComponent extends Destroyable {

	private readonly __appService = inject(AppService);

	protected _activatedRoute = inject(ActivatedRoute);

	constructor() {
		super();

		this.__appService.markAppReady();

		document.documentElement.classList.add('bp-three-ds-processing-result-callback-page');

		parent.bpThreeDsProcessingService.handleResultCallback(this._activatedRoute.snapshot.queryParams);
	}

}
