import { lowerCase } from 'lodash-es';
import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { inject, Injectable } from '@angular/core';

import { ResponseStatusCode } from '@bp/shared/models/core';
import { isURL } from '@bp/shared/utilities/core';
import { PaymentOptionType } from '@bp/shared/models/business';

import { TelemetryService } from '@bp/frontend/services/telemetry';
import { BpError } from '@bp/frontend/models/core';
import { MockedBackendState } from '@bp/frontend/services/persistent-state-keepers';

import { logNonCriticalPaymentRequestProperties } from '@bp/checkout-frontend/utilities';
import {
	ApmEtfTransaction,
	CryptoTransaction, IApiTransaction, IRequestCardDeposit, IS_MPI, RequestDeposit, Transaction
} from '@bp/checkout-frontend/models';

import { AppService } from './app.service';
import { PaymentApiService } from './api';
import { PaymentOptionInstancesManager } from './payment-option-instances-manager.service';

@Injectable({
	providedIn: 'root',
})
export class DepositProcessingService {

	protected readonly _appService = inject(AppService);

	private readonly __paymentsApiService = inject(PaymentApiService);

	private readonly __paymentOptionInstancesManager = inject(PaymentOptionInstancesManager);

	// eslint-disable-next-line rxjs/no-exposed-subjects
	terminated$ = new Subject();

	// eslint-disable-next-line rxjs/no-exposed-subjects
	redepositSuccessHtmlOrURL$ = new Subject<string>();

	private readonly __busy$ = new BehaviorSubject(false);

	private readonly __idle$ = this.__busy$.pipe(
		first(v => !v),
		takeUntil(this.terminated$),
	);

	async checkPspTransactionStatus(transactionId: string): Promise<void> {
		await firstValueFrom(this.__idle$);

		this.__busy$.next(true);

		TelemetryService.log('[Deposit processing service]: checkPspTransactionStatus', transactionId);

		this.__paymentsApiService
			.checkPspTransactionStatus(transactionId)
			.pipe(takeUntil(this.terminated$))
			.subscribe({
				next: trx => {
					this.handleAccordingTransaction(trx);

					this.__busy$.next(false);
				},
				error: this.__onApiResponseError,
			});
	}

	async deposit(depositRequest: RequestDeposit, redeposit?: boolean): Promise<void> {
		await firstValueFrom(this.__idle$);

		this.__busy$.next(true);

		logNonCriticalPaymentRequestProperties(
			`[Deposit processing service]: ${ redeposit ? 're' : '' }deposit`,
			depositRequest,
		);

		this.__depositBasedOnPaymentOptionType$(depositRequest)
			.pipe(takeUntil(this.terminated$))
			.subscribe({
				next: this.onTransactionResponse,
				error: this.__onApiResponseError,
			});
	}

	async redeposit(): Promise<void> {
		await this.deposit(this._appService.getPaymentRequestBody()!, true);
	}

	async continueMpiTransaction(transactionId: string): Promise<void> {
		await firstValueFrom(this.__idle$);

		this.__busy$.next(true);

		TelemetryService.log('[Deposit processing service]: continueMpiTransaction', transactionId);

		this.__paymentsApiService
			.transaction(transactionId)
			.pipe(takeUntil(this.terminated$))
			.subscribe({ next: this.onTransactionResponse, error: this.__onApiResponseError });
	}

	async continueProcessing(
		{
			transactionId,
			threeDsProcessorAuthResultReady,
		}: {
			transactionId: string;
			threeDsProcessorAuthResultReady?: boolean;
		},
	): Promise<void> {
		await firstValueFrom(this.__idle$);

		this.__busy$.next(true);

		TelemetryService.log('[Deposit processing service]: continue3DS', transactionId);

		const request = <IRequestCardDeposit>{
			...this._appService.getPaymentRequestBody(),
		};

		if (threeDsProcessorAuthResultReady)
			request.three_ds_provider_auth_result_ready = true;

		this.__paymentsApiService
			.continueProcessing(transactionId, request)
			.pipe(takeUntil(this.terminated$))
			.subscribe({ next: this.onTransactionResponse, error: this.__onApiResponseError });
	}

	private readonly onTransactionResponse = (trx: ApmEtfTransaction | CryptoTransaction | Transaction): void => {
		this._appService.transaction = trx;

		if (trx.isApproved || trx.isDeclined)
			this.__handleAccordingStatus(trx);
		else if ('open_in_new_window' in trx && trx.open_in_new_window && trx.html) {
			this._appService.removeAlertBeforeUnload();

			if (isURL(trx.html)) {
				if (this._appService.isEmbedded) {
					window.open(trx.html, '_blank', 'noreferrer');

					this._appService.navigate([ '/status/card-pending' ]);
				} else
					this._appService.changeWindowLocationTo(trx.html);
			} else
				this.redepositSuccessHtmlOrURL$.next(trx.html);

		} else if (trx.html) {
			this._appService.removeAlertBeforeUnload();

			if (trx.html.startsWith('http://')) {
				this._appService.navigate([ '/status/card-declined' ]);

				return;
			}

			this.redepositSuccessHtmlOrURL$.next(trx.html);
		} else
			this.handleAccordingTransaction(trx);

		this.__busy$.next(false);
	};

	handleAccordingTransaction(transaction: ApmEtfTransaction | CryptoTransaction | Transaction, immediately?: boolean): void {
		this._appService.transaction = transaction;

		if (transaction.isCreditCardTrxInProcess) {
			if (immediately)
				void this.checkPspTransactionStatus(transaction.id);
			else
				this.__scheduleCheckPspTransactionStatus(transaction);
		} else
			this.__handleAccordingStatus(transaction);
	}

	private __scheduleCheckPspTransactionStatus(trx: ApmEtfTransaction | CryptoTransaction | Transaction): void {
		setTimeout(async () => this.checkPspTransactionStatus(trx.id), (MockedBackendState.isActive ? 2 : 5) * 1000);
	}

	private __depositBasedOnPaymentOptionType$(depositRequest: RequestDeposit): Observable<ApmEtfTransaction | CryptoTransaction | Transaction> {
		const depositRequestType = PaymentOptionType.parseStrict(depositRequest.type);

		switch (depositRequestType) {
			case PaymentOptionType.creditCard:
				return this.__paymentsApiService.depositCreditCard(depositRequest);

			case PaymentOptionType.banks:

			case PaymentOptionType.apm:
				return this.__paymentsApiService.depositApm(depositRequest);

			case PaymentOptionType.crypto:
				return this.__paymentsApiService.depositCrypto(depositRequest);

			default:
				throw new Error('Missing payment method type');
		}
	}

	private __handleAccordingStatus(trx: ApmEtfTransaction | CryptoTransaction | Transaction | null): void {
		if (trx) {
			if (trx.isApproved) {
				if (this._appService.session!.isPayWithCheckout)
					this.__paymentOptionInstancesManager.successContinue(<Transaction>trx);
				else
					this._appService.navigate([ '/status/card-success' ]);
			} else if (trx.isTryAgain)
				void this.redeposit();
			else if (trx.isPending)
				this._appService.navigate([ '/status/pending' ]);
			else if ((this._appService.session!.isPayWithCheckout || this._appService.session!.isPaymentLinkCheckout || this._appService.isEmbedded) && trx.isDeclinedDueToInvalidCard && !IS_MPI) {
				this._appService.returnUrl
					? this._appService.navigateByUrl(this._appService.returnUrl)
					: this._appService.navigate([ '/' ]);
			} else if (this._appService.session!.isPayWithCheckout)
				this.__paymentOptionInstancesManager.handleDeclinedTransaction();
			else {
				this._appService.setError(trx.isDeclined
					? new BpError({
						status: ResponseStatusCode.TransactionDeclined,
						messages: [{
							message: lowerCase(trx.status),
						}],
					})
					: null);

				this._appService.navigateAccordingFinalTransactionStatus(trx);
			}
		} else
			this._appService.navigate([ '/error' ]);
	}

	private readonly __onApiResponseError = (error: BpError<IApiTransaction>): void => {
		this._appService.setError(error);

		const trx = this._appService.transaction = error.payload?.status
			? new Transaction(error.payload)
			: null;

		this.__handleAccordingStatus(trx);

		this.__busy$.next(false);
	};
}
